<template>
  <div class="content">
    <div v-show="isShow === true">
      <div class="card">
        <el-button type="primary" @click="handleAdd()" icon="el-icon-plus">添加</el-button>
        <el-button type="primary" plain @click="handleAdd(0)" icon="el-icon-upload2">导出</el-button>
        <div class="line"></div>
        <el-input placeholder="请输入代理商手机" style="width: 200px;margin-left:10px;" v-model="phone_num"></el-input>
        <el-select placeholder="请选择代理商" style="margin-left:10px;" v-model="hierarchy_id">
          <template v-for="(item, index) in hierarchy_list">
            <el-option :label="item.name" :value="item.hierarchy_id" :key="index"></el-option>
          </template>
        </el-select>
        <el-button type="primary" @click="fetchData()" style="margin-left:10px;">搜索</el-button>
      </div>
      <div class="table">
        <el-table :data="list" stripe style="width: 100%">
          <el-table-column align="center" prop="name" label="公司名称" fixed="left" width="250"></el-table-column>
          <el-table-column align="center" prop="legal_person_name" label="代理商姓名" width="150"></el-table-column>
          <el-table-column align="center" prop="phone_num" label="代理商手机号" width="150"></el-table-column>
          <el-table-column align="center" prop="hierarchy_connection" label="关联商家" width="150"></el-table-column>
          <el-table-column align="center" prop="store_scale" label="经营门店数量" width="150"></el-table-column>
          <el-table-column align="center" prop="child_agents" label="下级代理数量" width="150"></el-table-column>
          <el-table-column align="center" prop="child_merchant" label="入驻商家数量" width="150"></el-table-column>
          <el-table-column
            align="center"
            prop="audit_text"
            label="审核状态"
            column-key="audit_text"
            :filters="[
        {text: '审核通过', value: '审核通过'},
        {text: '审核不通过', value: '审核不通过'},
        {text: '待审核', value: '待审核'}
        ]"
            :filter-method="filterHandler"
            width="150"
          ></el-table-column>
          <el-table-column align="center" prop="create_admin_user" label="添加人" width="150"></el-table-column>
          <el-table-column align="center" prop="audit_admin_user" label="审核人" width="150"></el-table-column>
          <el-table-column align="center" prop="update_admin_user" label="更新人" width="150"></el-table-column>
          <el-table-column align="center" prop="update_time" label="更新时间" width="200"></el-table-column>
          <el-table-column align="center" prop="audit_time" label="审核时间" width="200"></el-table-column>
          <el-table-column
            align="center"
            prop="status_text"
            label="合作关系"
            column-key="status_text"
            :filters="[
        {text: '正常合作', value: '正常合作'},
        {text: '终止合作', value: '终止合作'}
        ]"
            :filter-method="filterHandler"
            width="400"
          ></el-table-column>
          <el-table-column align="center" fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.select"
                placeholder="请选择"
                @change="handleChange($event, scope.row)"
              >
                <el-option label="审核" :value="0" v-if="scope.row.audit_text === '待审核'"></el-option>
                <!-- <el-option label="查看" :value="1"></el-option> -->
                <el-option label="终止合作" :value="2" v-if="scope.row.status_text === '正常合作'"></el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagina">
          <el-pagination background layout="prev, pager, next" :total="total"></el-pagination>
        </div>
      </div>
    </div>
    <div class="about" v-show="isShow === false">
      <AgentAdd @recapture="handleAgain()" />
    </div>
    <el-dialog
      :visible.sync="stopVisible"
      width="30%"
      :destroy-on-close="true"
      :before-close="handleCancle"
    >
      <span>您确认与代理商为 深圳市金六福珠宝公司 终止代理合作关系，一旦终止，该代理商的所有商家将归E店宝管理?</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancle">取 消</el-button>
        <el-button type="primary" @click="handleStop">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="auditVisible"
      width="30%"
      :destroy-on-close="true"
      :before-close="() => {auditVisible = false}"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="原因说明">
          <el-radio-group v-model="form.audit" @change="handleAudit">
            <el-radio label="20">审核通过</el-radio>
            <el-radio label="30">审核不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="原因说明" v-if="auditType === '2'">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入审核不通过原因说明"
            v-model="form.audit_remark"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
          <el-button @click="() => {auditVisible = false}">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getList,
  getSelectList,
  stopCooperate,
  agentAudit,
} from "@/api/agentManage/index";
import AgentAdd from "../add/index";
export default {
  data() {
    return {
      type: "add",
      dialogFormVisible: false,
      form: {},
      list: [],
      total: 0,
      isShow: true,
      stopVisible: false,
      auditVisible: false,
      phone_num: "",
      hierarchy_id: "",
      hierarchy_list: [],
      company_list: [],
      form: {},
      auditType: 1,
      agentId: "",
    };
  },
  components: {
    AgentAdd,
  },
  created() {
    this.fetchData();
    this.fetchHierarchy();
  },
  methods: {
    /*
     *  获取列表数据
     */
    fetchData() {
      const data = {
        type_id: 20,
      };
      if (this.phone_num) {
        data.phone_num = this.phone_num;
      }
      if (this.hierarchy_id) {
        data.hierarchy_id = this.hierarchy_id;
      }
      getList(data).then((res) => {
        if (res.code === 1) {
          this.list = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  添加代理商
     */
    handleAdd() {
      this.isShow = false;
    },
    /*
     *  重新加载列表
     */
    handleAgain() {
      this.isShow = true;
      this.fetchData();
    },
    filterHandler(value, row, column) {
      const property = column["property"];
      return row[property] === value;
    },
    /*
     *   操作
     */
    handleChange(e, current) {
      this.agentId = current.hierarchy_id;
      if (e === 2) {
        this.stopVisible = true;
      } else if (e === 0) {
        this.auditVisible = true;
      }
    },
    /*
     *  关闭终止合作弹窗
     */
    handleCancle() {
      this.stopVisible = false;
      this.fetchData();
    },
    /*
     *  确定终止合作关系
     */
    handleStop() {
      const data = {
        hierarchy_id: this.agentId,
      };
      stopCooperate(data).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: "终止合作成功！",
            type: "success",
          });
          this.handleCancle();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    /*
     *  获取代理商下拉数据
     */
    fetchHierarchy() {
      const data = {
        type: 1,
      };
      getSelectList(data).then((res) => {
        if (res.code === 1) {
          this.hierarchy_list = res.data;
        }
      });
    },
    /*
     *  原因说明动态处理
     */
    handleAudit(e) {
      this.auditType = e;
    },
    /*
     *  提交原因说明
     */
    onSubmit() {
      const data = {
        hierarchy_id: this.agentId,
        audit: this.form.audit,
      };
      if (this.form.audit_remark) {
        data.audit_remark = this.form.audit_remark
      } 
      console.log(data);
      agentAudit(data).then((res) => {
        if (res.code === 1) {
          this.auditVisible = false;
          this.fetchData();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .line {
  display: inline-block;
  vertical-align: middle;
  margin: 0 30px;
  width: 1px;
  height: 40px;
  background: #e9e9e9;
}

/deep/ .about {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
