<template>
  <div>
    <div class="content">
      <div class="newly">
        <el-page-header @back="goBack" content="添加代理商信息"></el-page-header>
      </div>
    </div>
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <div class="newForm">
        <div class="title">
          <div class="span"></div>代理商信息
        </div>
        <div class="cutLine"></div>
        <div class="form">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="关联商家" :label-width="formLabelWidth" prop="hierarchy_connection">
                <el-select
                  v-model="form.hierarchy_connection"
                  placeholder="请选择关联商家"
                  style="width: 375px"
                >
                  <template v-for="(item, index) in hierarchy_list">
                    <el-option :label="item.name" :value="item.hierarchy_id" :key="index"></el-option>
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="代理商姓名" :label-width="formLabelWidth" prop="legal_person_name">
                <el-input
                  v-model="form.legal_person_name"
                  placeholder="请输入代理商姓名"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="手机号码" :label-width="formLabelWidth" prop="phone_num">
                <el-input v-model="form.phone_num" placeholder="请输入手机号码" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="身份证认证" :label-width="formLabelWidth" required>
                <div style="display: flex;flex-direction: row;">
                  <el-upload
                    action="https://api.etouch.top/admin/common.Common/uploadOssImage"
                    :data="{
                      directory: 'AGENT_INFO'
                    }"
                    :headers="{
                        authorization: token
                    }"
                    :show-file-list="false"
                    :on-success="handlePicpositiveSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img v-if="positive_img" :src="positive_img" class="avatar" />
                    <img v-else :src="positive" class="avatar" />
                  </el-upload>
                  <el-upload
                    action="https://api.etouch.top/admin/common.Common/uploadOssImage"
                    :data="{
                      directory: 'AGENT_INFO'
                    }"
                    :headers="{
                        authorization: token
                    }"
                    :show-file-list="false"
                    :on-success="handlePicnegativeSuccess"
                    :before-upload="beforeAvatarUpload"
                    style="margin-left:20px"
                  >
                    <img
                      v-if="negative_img"
                      :src="negative_img"
                      style="margin-left:20px"
                      class="avatar"
                    />
                    <img v-else :src="negative" class="avatar" />
                  </el-upload>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="newForm">
        <div class="title">
          <div class="span"></div>公司信息
        </div>
        <div class="cutLine"></div>
        <div class="form">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="商家类型" :label-width="formLabelWidth" prop="agent_type">
                <el-select v-model="form.agent_type" placeholder="请选择商家类型" style="width: 375px">
                  <el-option label="个人" value="10"></el-option>
                  <el-option label="公司" value="20"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="公司性质" :label-width="formLabelWidth">
                <el-select
                  v-model="form.company_type_id"
                  placeholder="请选择公司性质"
                  style="width: 375px"
                >
                  <el-option label="国有企业" value="1"></el-option>
                  <el-option label="私营企业" value="2"></el-option>
                  <el-option label="港资企业" value="3"></el-option>
                  <el-option label="外企" value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="公司名称" :label-width="formLabelWidth">
                <el-input v-model="form.name" autocomplete="off" placeholder="请选择公司名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所属区域" :label-width="formLabelWidth">
                <el-row>
                  <el-col :span="8">
                    <el-select v-model="form.provinceId" placeholder="请选择省" @change="handleProvie">
                      <template v-for="(item, index) in provinceList">
                        <el-option :label="item.name" :value="item.id" :key="index"></el-option>
                      </template>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <el-select v-model="form.cityId" placeholder="请选择市" @change="handleCity">
                      <template v-for="(item, index) in cityList">
                        <el-option :label="item.name" :value="item.id" :key="index"></el-option>
                      </template>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <el-select v-model="form.areaId" placeholder="请选择区" @change="handleArea">
                      <template v-for="(item, index) in areaList">
                        <el-option :label="item.name" :value="item.id" :key="index"></el-option>
                      </template>
                    </el-select>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="详细地址" :label-width="formLabelWidth">
                <el-input v-model="form.addr" autocomplete="off" placeholder="请输入详细地址"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="经营门店数量" :label-width="formLabelWidth">
                <el-input v-model="form.store_scale" autocomplete="off" placeholder="请输入经营门店数量"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="经营品牌" :label-width="formLabelWidth">
                <el-input v-model="form.brand" autocomplete="off" placeholder="请输入经营品牌"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="公司营业执照" :label-width="formLabelWidth">
                <div style="display: flex;flex-direction: row;">
                  <el-upload
                    class="avatar-uploader"
                    action="https://api.etouch.top/admin/common.Common/uploadOssImage"
                    :data="{
                      directory: 'AGENT_INFO'
                    }"
                    :headers="{
                        authorization: token
                    }"
                    :show-file-list="false"
                    :on-success="handleLicenseUrlSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img
                      v-if="license_img"
                      :src="license_img"
                      style="margin-left:20px"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="备注信息" :label-width="formLabelWidth">
                <el-input
                  v-model="form.remark"
                  type="textarea"
                  autocomplete="off"
                  placeholder="请输入备注信息"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="newForm">
        <div class="title">
          <div class="span"></div>代理商管理账号
        </div>
        <div class="cutLine"></div>
        <div class="form">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="管理员姓名" :label-width="formLabelWidth" prop="admin_name">
                <el-input v-model="form.admin_name" autocomplete="off" placeholder="请输入管理员姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="管理员手机" :label-width="formLabelWidth" prop="admin_account">
                <el-input v-model="form.admin_account" autocomplete="off" placeholder="请输入管理员手机"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>
    <div style="margin-top:20px;margin-bottom:20px;float:right;display:flex;flex-desition:row">
      <el-button type="primary" @click="submit('ruleForm')">保存</el-button>
      <el-button @click="goBack()">取消</el-button>
    </div>
  </div>
</template>

<script>
import {
  getRegionList,
  getSelectList,
  createRegion,
} from "@/api/agentManage/index";
import negative from "../../../../assets/images/formAbout/icon_negative.png";
import positive from "../../../../assets/images/formAbout/icon_positive.png";
import { uploadOssFile } from "@/api/upload/upload";
import Cookies from "js-cookie";
import storage from "good-storage";
export default {
  data() {
    return {
      form: {},
      formLabelWidth: "150px",
      imageUrl: "",
      positive: positive,
      negative: negative,
      provinceList: [],
      cityList: [],
      areaList: [],
      license_url: "", // 营业执照
      license_img: "",
      hierarchy_list: [],
      icon_positive: "", // 身份证正面
      positive_img: "",
      icon_negative: "", // 身份证反面
      negative_img: "",
      region_id: "", // 区域id
      rules: {
        hierarchy_connection: [
          { required: true, message: "请选择关联商家", trigger: "change" },
        ],
        legal_person_name: [
          { required: true, message: "请输入代理商姓名", trigger: "blur" },
        ],
        phone_num: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
        ],
        agent_type: [
          { required: true, message: "请选择商家类型", trigger: "change" },
        ],
        admin_name: [
          { required: true, message: "请输入管理员姓名", trigger: "blur" },
        ],
        admin_account: [
          { required: true, message: "请输入管理员手机号", trigger: "blur" },
        ],
      },
      token: "",
    };
  },
  created() {
    this.getProvinceList();
    this.getRegenList();
    this.token = storage.session.get("token") || Cookies.get("token");
  },
  methods: {
    /*
     *  关联商家数据
     */
    getRegenList() {
      const data = {
        type: 2,
      };
      getSelectList(data).then((res) => {
        if (res.code === 1) {
          if (res.data && res.data.length !== 0) {
            this.hierarchy_list = res.data;
          }
        }
      });
    },
    /*
     *  省市区下拉数据
     */
    getProvinceList() {
      const data = {
        pid: 1,
      };
      getRegionList(data).then((res) => {
        if (res.code === 1) {
          this.provinceList = res.data;
        }
      });
    },
    /*
     *   获取省id
     */
    handleProvie(id) {
      this.region_id = id;
      const data = {
        pid: id,
      };
      getRegionList(data).then((res) => {
        if (res.code === 1) {
          this.cityList = res.data;
        }
      });
    },
    /*
     *  获取城市id
     */
    handleCity(id) {
      this.region_id = id;
      const data = {
        pid: id,
      };
      getRegionList(data).then((res) => {
        if (res.code === 1) {
          this.areaList = res.data;
        }
      });
    },
    /*
     *   获取区id
     */
    handleArea(id) {
      this.region_id = id;
    },
    /*
     *  保存
     */
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = { ...this.form };
          delete form.areaId;
          delete form.cityId;
          delete form.provinceId;

          form.legal_person_img_url =
            this.icon_positive + "," + this.icon_negative || "";
          form.region_id = this.region_id; // 区域ID
          form.license_url = this.license_url || ""; // 营业执照
          form.type_id = "20";
          createRegion(form).then((res) => {
            if (res.code === 1) {
              this.goBack();
            }
          });
        } else {
          this.$message({
            message: "请检查必填项信息",
            type: "warning",
          });
          return false;
        }
      });
    },
    /*
     *  身份证正面
     */
    handlePicpositiveSuccess(res, file) {
      this.icon_positive = res.data.data;
      this.positive_img = URL.createObjectURL(file.raw);
    },
    /*
     *  身份证反面
     */
    handlePicnegativeSuccess(res, file) {
      this.icon_negative = res.data.data;
      this.negative_img = URL.createObjectURL(file.raw);
    },
    /*
     *  营业执照图片上传
     */
    handleLicenseUrlSuccess(res, file) {
      this.license_url = res.data.data;
      this.license_img = URL.createObjectURL(file.raw);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview() {},
    beforeAvatarUpload() {},
    /*
     *  返回
     */
    goBack() {
      this.$emit("recapture");
    },
  },
};
</script>

<style scoped  lang="less">
/deep/ .title {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  width: 100%;
  height: 60px;
  line-height: 60px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}

/deep/ .form {
  width: 90%;
  margin-top: 30px;
  padding-left: 20px;
}

/deep/ .cutLine {
  width: 100%;
  height: 1px;
  background: #f5f6fa;
}

/deep/ .newly {
  padding: 20px;
  width: 100%;
}
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .avatar {
  width: 290px;
  height: 170px;
  display: block;
}
</style>